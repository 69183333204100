// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("trix")
require("@rails/actiontext")

import "../src/select2.css";
import "../src/select2-bootstrap4.css";
import "../src/actiontext.scss";
import "../src/style.scss";
import "@fortawesome/fontawesome-free/js/all";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
import { select2 } from './select2';

$(document).ready(function() {
  $('.item-container img').on(
    'click',
    function(event) {
      let item = event.target.dataset.id;
      $.get("/items/" + item + ".js", function(data, status) {
        document.getElementById("selected-file-container").innerHTML = data;
      });
    }
  );

  $('#reset-filters').on(
    'click',
    function(event) {
      $('#filter-form input[type=checkbox]').prop('checked',false);
      $('#filter-form').submit();
    }
  );

  $('.collapse').on(
    'show.bs.collapse', 
    function(event) {
      let cardHeader = event.target.previousElementSibling;
      let src = cardHeader.getElementsByTagName("img")[0].src;
      cardHeader.getElementsByTagName("img")[0].src = src.replace("left", "down");
    }
  ).on(
    'hide.bs.collapse', 
    function(event) {
      let cardHeader = event.target.previousElementSibling;
      let src = cardHeader.getElementsByTagName("img")[0].src;
      cardHeader.getElementsByTagName("img")[0].src = src.replace("down", "left");
    }
  );

  $('.custom-control-input').on(
    'change',
    function(event) {
      $('#filter-form').submit();
    }
  );

  $('#item_attachment').on(
    'change',
    function(){
      let fileName = $(this).val();
      fileName = fileName.substring(fileName.lastIndexOf('\\')+1);
      $(this).next('.custom-file-label').html(fileName);
    }
  );

  $('#selected-cat a').on(
    'click',
    function(event) {
      let filter = event.target.dataset.filter;
      document.getElementById(filter).checked = false;
      $('#filter-form').submit();
    }
  );

  $('select.autocomplete').select2({ 
    theme: 'bootstrap4'
  });
});